// Generated by Framer (667f196)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["YpUMWiIvp"];

const variantClassNames = {YpUMWiIvp: "framer-v-1fhvvlf"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "YpUMWiIvp", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "YpUMWiIvp", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-7QdAD", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1fhvvlf", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"YpUMWiIvp"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-rpcoe4"} data-framer-name={"2 Frame 5"} fill={"rgba(0,0,0,1)"} intrinsicHeight={252} intrinsicWidth={252} layoutDependency={layoutDependency} layoutId={"JxIQNl2qJ"} svg={"<svg width=\"252\" height=\"252\" viewBox=\"0 0 252 252\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<g filter=\"url(#filter0_d_4252_24827)\">\n<g clip-path=\"url(#clip0_4252_24827)\">\n<rect x=\"0.884033\" y=\"0.35498\" width=\"250.161\" height=\"251.325\" rx=\"6.98125\" fill=\"#442B7E\"/>\n</g>\n</g>\n<defs>\n<filter id=\"filter0_d_4252_24827\" x=\"-22.116\" y=\"-2.64502\" width=\"296.161\" height=\"297.325\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\">\n<feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/>\n<feColorMatrix in=\"SourceAlpha\" type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\" result=\"hardAlpha\"/>\n<feOffset dy=\"20\"/>\n<feGaussianBlur stdDeviation=\"11.5\"/>\n<feComposite in2=\"hardAlpha\" operator=\"out\"/>\n<feColorMatrix type=\"matrix\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0\"/>\n<feBlend mode=\"normal\" in2=\"BackgroundImageFix\" result=\"effect1_dropShadow_4252_24827\"/>\n<feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_dropShadow_4252_24827\" result=\"shape\"/>\n</filter>\n<clipPath id=\"clip0_4252_24827\">\n<rect x=\"0.884033\" y=\"0.35498\" width=\"250.161\" height=\"251.325\" rx=\"6.98125\" fill=\"white\"/>\n</clipPath>\n</defs>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-7QdAD [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7QdAD * { box-sizing: border-box; }", ".framer-7QdAD .framer-1y9vrt0 { display: block; }", ".framer-7QdAD .framer-1fhvvlf { height: 252px; overflow: hidden; position: relative; width: 242px; }", ".framer-7QdAD .framer-rpcoe4 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 252
 * @framerIntrinsicWidth 242
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const Framerwvlp36MSm: React.ComponentType<Props> = withCSS(Component, css, "framer-7QdAD") as typeof Component;
export default Framerwvlp36MSm;

Framerwvlp36MSm.displayName = "2 Frame 5";

Framerwvlp36MSm.defaultProps = {height: 252, width: 242};

addFonts(Framerwvlp36MSm, [])